const LOG_CONSOLE = true;
export const API_BASE_URL = isProduction() ? `${process.env.REACT_APP_API}` : "http://localhost:3001";
export const SOCKET_NAMESPACE_MAIN = isProduction() ? "https://zengiving.co" : "http://localhost:3001";
export const PATH_FOR_SOCKET = (process.env.NODE_ENV !== 'development') ? "/api/socket.io/" : "/socket.io/";
export const localInfo = (...msg) => {
  if (!isProduction()) {
    for (let m of msg) {
      if (typeof m === "object") {
        console.info(`%c[KHAI_LOG] Object: `, 'color: green');
        console.info(m);
      }
      else {
        console.info(`%c[KHAI_LOG] %c${m}`, 'color: blue', 'color: green');
      }
    }
  }
};

export const localError = (...msg) => {
  if (!isProduction()) {
    console.log("%c[KHAI_ERROR] %c" + msg, 'color: red', 'color: red; background-color: cyan; font-weight: bold');
    var stack = new Error().stack;
    console.log(stack);

  }
};

export function isProduction(productionDebug=LOG_CONSOLE) {
  return (process.env.NODE_ENV !== 'development') && productionDebug;
}
